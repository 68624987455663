import React, { useContext } from "react"
import clsx from "clsx"
import { makeComponentStyles } from "../components/mixins"
import Container from "@components/Container/Container.jsx"
import MainWrap from "@components/Wrap/MainWrap"
import IconButton from "@components/Buttons/IconButton"
import ButtonLink from "@components/Buttons/ButtonLink"
import Layout from "../components/Layout/Layout"
import Chip from "@components/Cards/Chip.jsx"
import { Box, Typography, Grid, Avatar, makeStyles } from "@material-ui/core"
import infoLabel from "@static/images/Info.svg"
import { graphql } from "gatsby";
import Markdown from "markdown-to-jsx";
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../heplers.js"
import FloatWrap from "@components/Wrap/FloatWrap.jsx"
import Seo from "@components/seo"

const styles = ({ palette, breakpoints }) => ({
    // <<<<<<<<<<<<<<<< Блок "Досуг"  >>>>>>>>>>>>>>>>>>>>>
    leisure__container: {
        display: "flex",
        paddingBottom: "20px",
        [breakpoints.down("md")]: {
            flexDirection: "column-reverse",
        },
    },
    leisure__img: {
        [breakpoints.down("md")]: {
            paddingBottom: "24px",
        },
    },
    // <<<<<<<<<<<<<<<< Блок "Важно"  >>>>>>>>>>>>>>>>>>>>>
    important__header: {
        padding: "30px 0",
        [breakpoints.down("md")]: {
            paddingBottom: "16px",
        },
    },
    chiptext: {
        fontSize: "18px",
        [breakpoints.down('sm')]: {
            fontSize: "14px"
        }
    },
    important__container: {
        display: "flex",
        alignItems: "center",
    },
    important__image: {
        [breakpoints.down("md")]: {
            display: "none",
        },
    },
    // <<<<<<<<<<<<<<<< Блок "Места досуга"  >>>>>>>>>>>>>>>>>>>>>
    place__padding: {
        paddingTop: "30px",
        paddingBottom: "50px",
        [breakpoints.down("sm")]: {
            paddingBottom: "0px",
        },
    },
    place__card_container: {
        padding: "15px",
    },
    place__card_width: {
        display: "flex",
        [breakpoints.down("sm")]: {
            display: "flex",
            width: "310px",
        }
    },
    place__card: {
        display: "flex",
        padding: "30px 0",
        height: "182px",
        backgroundColor: palette.primary.light,
        [breakpoints.down("sm")]: {
            backgroundColor: palette.white.main,
            justifyContent: "center",
            padding: "0",
            height: "100px",
        },
    },
    place__icon_box: {
        width: "66px",
        height: "66px",
        marginLeft: "10px",
        "&::before": {
            content: '""',
            position: "absolute",
            zIndex: 1,
            borderRadius: "50%",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
        },
    },
    place__card_header: {
        paddingBottom: "5px",

    },
    place__text_padding: {
        paddingLeft: "24px",
        [breakpoints.down("md")]: {
            paddingLeft: "15px",
        },
    },
    place__linker: {
        display: 'contents',
        color: palette.primary.main,
        fontSize: "18px !important",
        lineHeight: "21px !important",
        [breakpoints.down("sm")]: {
            fontSize: "14px !important",
            lineHeight: "17px !important"
        },
    },
    // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
    desktop_text: {
        fontSize: "18px",
        lineHeight: "21px",
        fontWeight: "400",
    },
    mobile_text: {
        [breakpoints.down("sm")]: {
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "17px"
        }
    },
    bold_weight: {
        fontWeight: "500",
    },
})

function go(link) {
    window.open(link, '_blank');
}

const makePlaceImage = (url) => makeStyles({
    root: {
        "&::before": {
            background: `url(${url}) center no-repeat`,
        },
    }
})().root;

const Leisure = ({ data }) => {

    const {
        // <<<<<<<<<<<<<<<< Блок "Досуг"  >>>>>>>>>>>>>>>>>>>>>
        leisure__container,
        leisure__img,
        // <<<<<<<<<<<<<<<< Блок "Важно"  >>>>>>>>>>>>>>>>>>>>>
        important__header,
        chiptext,
        important__container,
        // important__text,
        // <<<<<<<<<<<<<<<< Блок "Места досуга"  >>>>>>>>>>>>>>>>>>>>>
        place__padding,
        place__card_container,
        place__card,
        place__card_width,
        place__icon_box,
        place__card_header,
        place__text_padding,
        place__linker,
        // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
        desktop_text,
        mobile_text,
        bold_weight,
    } = makeComponentStyles(styles);

    const { language } = useContext(IntlContextConsumer);
    const intl = useIntl();
    const {
        Image,
        ImportantInfo,
        Info,
        LeisureCards,
        LeisureSEO,
    } = getStrapiContentByLang(data.allStrapiLeisure.edges, language);

    return (
      <div>
          <Seo title={LeisureSEO?.TitleSEO}
               description={LeisureSEO?.DescriptionSEO}
               keywords={LeisureSEO?.KeywordsSEO.split(',')}
        />

        <>
            <Layout>
                  {/*<<<<<<<<<<<<<<<< Блок "Досуг"  >>>>>>>>>>>>>>>>>>>>>*/}
                  <Container mw={"lg"}>
                      <MainWrap>
                          <MainWrap.Header>
                              <Typography component={"h1"} variant={"h2"} style={{ color: "#000" }}>
                                  {intl.formatMessage({ id: 'freetime' })}
                              </Typography>
                          </MainWrap.Header>
                          <MainWrap.Body>
                              <FloatWrap className={clsx(desktop_text, mobile_text)} Img={Image.localFile.publicURL} Float='right'>
                                  <Markdown>{Info}</Markdown>
                              </FloatWrap>
                          </MainWrap.Body>
                      </MainWrap>
                  </Container>
                  {/*<<<<<<<<<<<<<<<< Блок "Важно"  >>>>>>>>>>>>>>>>>>>>>*/}
                  <Container mw={"lg"}>
                      <Grid container>
                          <Grid className={important__header} item xs={12}>
                              <Chip avatar={<Avatar src={infoLabel} />} label={intl.formatMessage({ id: 'important' })} straight className={chiptext} />
                          </Grid>
                          <Grid item xs={12} md={12}>
                              <div className={important__container}>
                                  <Typography className={clsx(desktop_text, mobile_text)}>
                                      <Markdown>{ImportantInfo}</Markdown>
                                  </Typography>
                              </div>
                          </Grid>
                      </Grid>
                  </Container>
                  {/*<<<<<<<<<<<<<<<< Блок "Места досуга"  >>>>>>>>>>>>>>>>>>>>>*/}
                  <Container className={place__padding} mw={"lg"}>
                      <Grid className={place__card_container} container spacing={3}>
                          {
                              LeisureCards.map(({ Description, Image, Title, Site }) => {
                                  return (
                                    <Grid item xs={12} md={4}>
                                        <Box className={place__card}>
                                            <div className={place__card_width}>
                                                <IconButton className={clsx(place__icon_box, makePlaceImage(
                                                  Image.localFile.publicURL
                                                ))}
                                                            color="blue" />
                                                <div className={place__text_padding}>
                                                    <Typography
                                                      className={clsx(place__card_header, desktop_text, mobile_text, bold_weight)}>
                                                        {Title}
                                                    </Typography>
                                                    {Site ? <ButtonLink className={clsx(desktop_text, mobile_text, place__linker)}
                                                                        onClick={() => go(Site)}>
                                                        {intl.formatMessage({ id: 'site' })}
                                                    </ButtonLink> : null}<br />
                                                    <Typography className={clsx(desktop_text, mobile_text)}>
                                                        <Markdown>{Description}</Markdown>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Box>
                                    </Grid>
                                  )
                              })
                          }
                      </Grid>
                  </Container>
              </Layout>
        </>
      </div>
    )
}

export default Leisure;
export const pageQuery = graphql`{
  allStrapiLeisure {
    edges {
        node {
            locale
            Image {
                localFile {
                    publicURL
                }
            }
            ImportantInfo
            Info
            LeisureCards {
                Description
                Image {
                    localFile {
                        publicURL
                    }
                }
                Site
                Title
            }
            LeisureSEO {
              DescriptionSEO
              KeywordsSEO
              TitleSEO
            }
        }
    }
}
}`;
